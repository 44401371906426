export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'model',
    label: 'langkey.breadcrumb-model',
    isCarSelector: true,
    step: 2,
  },
  {
    key: 'type',
    label: 'langkey.breadcrumb-type',
    isCarSelector: true,
    step: 3,
  },
  {
    key: 'version',
    label: 'langkey.breadcrumb-version',
    isCarSelector: true,
    step: 4,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
    to: 'wheels',
  },
  {
    key: 'tire',
    label: 'Tires',
  },
];

export const breadcrumbItemsNoState = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    isCarSelector: true,
    step: 1,
  },
  {
    key: 'wheels',
    label: 'langkey.breadcrumb-wheels',
    to: 'wheels',
  },
  {
    key: 'wheel-detail',
    label: 'langkey.breadcrumb-wheels-details',
    to: 'wheel-detail',
  },
  {
    key: 'tpms',
    label: 'TPMS',
    to: 'tpms',
  },
  {
    key: 'tire',
    label: 'langkey.breadcrumb-tyres',
  },
];

export const tableFieldSelectedItem = [
  { value: 'image', text: 'langkey.image', width: '200px', align: 'center' },
  { value: 'description', text: 'langkey.article', width: '300px', align: 'center' },
  { value: 'articleId', text: 'langkey.article-id', width: '135px', align: 'center' },
  { value: 'quantity', text: 'langkey.quantity', width: '180px', align: 'center' },
  { value: 'total', text: 'langkey.total-price', width: '110px', align: 'center' },
  { value: 'data-table-expand' },
];

export const tyreCarTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '95px',
      desktopLg: '95px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '150px',
      desktopLg: '150px',
    },
    maxWidth: {
      desktop: '150px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.runflat',
    align: 'center',
    value: 'runFlat',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.list-price',
    align: 'center',
    value: 'prices',
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: '%',
    align: 'center',
    value: 'discount',
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.pp',
    align: 'center',
    value: 'purchasePrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.offer',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
];

export const tyreCarRetailPriceTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '95px',
      desktopLg: '95px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '150px',
      desktopLg: '150px',
    },
    maxWidth: {
      desktop: '150px',
      desktopLg: '1fr',
    },
  },
  {
    text: '',
    value: 'rollingResistance',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'wetGrip',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: '',
    value: 'noiseClass',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.runflat',
    align: 'center',
    value: 'runFlat',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'S',
    align: 'center',
    value: 'season',
    minWidth: {
      desktop: '28px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.netto',
    align: 'center',
    value: 'retailPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.brutto',
    align: 'center',
    value: 'retailTaxPrice',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.offer',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
];

export const tyreCarTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%` },
  { text: 'langkey.list-price', value: 'listPrice', width: `${(2/18) * 100}%` },
  { text: 'langkey.discount', value: 'discount', width: `${(2/18) * 100}%` },
  { text: 'langkey.price', value: 'purchasePrice', width: `${(2/18) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/18) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%` },
];

export const tyreCarRetailPriceTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%` },
  { text: 'langkey.netto', value: 'retailPrice', width: `${(3/18) * 100}%` },
  { text: 'langkey.brutto', value: 'retailTaxPrice', width: `${(3/18) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/18) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%` },
];

export const selectFilterFields = [
  {
    key: 'tyreSize',
    filterKey: 'tyreSizes',
    placeholder: '',
    singleSelectLabel: 'langkey.tyre-size',
    pluralSelectLabel: 'langkey.tyre-sizes',
    single: true,
    required: true,
    searchOnSelect: true,
  },
  {
    key: 'loadIndex',
    filterKey: 'loadIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.load-index',
    pluralSelectLabel: 'langkey.load-indexes',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'speedIndex',
    filterKey: 'speedIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.speed-index',
    pluralSelectLabel: 'langkey.speed-indexes',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    searchOnSelect: true,
    isSearchSubmit: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'oeIdentifier',
    filterKey: 'oeIdentifiers',
    placeholder: '',
    singleSelectLabel: 'langkey.oe-identifier',
    pluralSelectLabel: 'langkey.oe-identifiers',
    requiredValueBefore: ['tyreSizes'],
    single: true,
    searchOnSelect: true,
  },
];

export const selectFilterFieldsDisabled = [
  {
    key: 'tyreSize',
    filterKey: 'tyreSizes',
    placeholder: '',
    singleSelectLabel: 'langkey.tyre-size',
    pluralSelectLabel: 'langkey.tyre-sizes',
    single: true,
    required: true,
  },
  {
    key: 'loadIndex',
    filterKey: 'loadIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.load-index',
    pluralSelectLabel: 'langkey.load-indexes',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'speedIndex',
    filterKey: 'speedIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.speed-index',
    pluralSelectLabel: 'langkey.speed-indexes',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['tyreSizes'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    searchOnSelect: true,
    isSearchSubmit: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'oeIdentifier',
    filterKey: 'oeIdentifiers',
    placeholder: '',
    singleSelectLabel: 'langkey.oe-identifier',
    pluralSelectLabel: 'langkey.oe-identifiers',
    requiredValueBefore: ['tyreSizes'],
    single: true,
    searchOnSelect: true,
  },
];

export const attributeFilterFields = [
  {
    filterKey: 'seasons',
    filterValue: 'S',
    area: 'summer',
    value: 'summer',
    icon: 'summer',
  },
  {
    filterKey: 'seasons',
    filterValue: 'W',
    area: 'winter',
    value: 'winter',
    icon: 'winter',
  },
  {
    filterKey: 'seasons',
    filterValue: 'A',
    area: 'allSeason',
    value: 'allSeason',
    icon: 'all-season',
  },
  {
    filterKey: 'strengthened',
    filterValue: 'strengthened',
    area: 'seal',
    value: 'langkey.xl',
    isBoolean: true,
  },
  {
    filterKey: 'runFlat',
    filterValue: 'runFlat',
    area: 'runFlat',
    value: 'langkey.runflat',
    isBoolean: true,
  },
  {
    filterKey: 'threePeakMountainSnowFlake',
    filterValue: 'threePeakMountainSnowFlake',
    area: 'threePeakMountainSnowFlake',
    value: 'langkey.3mpsf',
    isBoolean: true,
  },
  {
    filterKey: 'manufacturerClasses',
    filterValue: 'premium',
    area: 'premium',
    value: 'langkey.premium',
  },
  {
    filterKey: 'manufacturerClasses',
    filterValue: 'economy',
    area: 'economy',
    value: 'langkey.economy',
  },
  {
    filterKey: 'manufacturerClasses',
    filterValue: 'budget',
    area: 'budget',
    value: 'langkey.budget',
  },
];

export const rangeFilterFields = [
  {
    filterKey: 'rollingResistances',
    icon: 'rolling-resistance',
  },
  {
    filterKey: 'wetGrips',
    icon: 'wet-grip',
  },
  {
    filterKey: 'noiseClasses',
    icon: 'volume',
  },
];