<template>
  <section class="rims-config_carTag-tyre">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
      @on-click-wheels="onClickWheels"
      @on-click-wheel-detail="onClickWheelDetail"
      @on-click-tpms="onClickTpms"
    />
    <rimconfig-select-history-bar />
    <tyre-search
      ref="tyreSearchRimconfigCartagTyre"
      class="tyre-search-rimconfig-cartag-tyre"
      :table-field="tyreCarTableField"
      :table-field-expand="tyreCarTableFieldExpand"
      :table-field-selected-item="tableFieldSelectedItem"
      :table-items="tyreData"
      :select-filter-options="selectFilterOptions"
      :loading="isLoading"
      :is-loading-pagination="isLoadingPagination"
      :is-loading-filter="isLoadingFilter"
      :is-start="isStart"
      :is-no-more-pagination-result="isNoMorePaginationResult"
      :select-filter-fields="selectedSelectFilterFields"
      :attribute-filter-fields="attributeFilterFields"
      :range-filter-fields="rangeFilterFields"
      @on-sort="onSortTable"
      @next-page="onNextPage"
      @input-select-filter="onInputSelectFilter"
      @search-filter="onSearchFilter"
      @clear="onClearTable"
    />
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/rims-config_carTag-tyre.scss";
</style>

<script>
import RimconfigSelectHistoryBar from '@/components/RimconfigSelectHistoryBar';
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import TyreSearch from '@/components/TyreSearch';
import {
  breadcrumbItemsNoState,
  tableFieldSelectedItem,
  tyreCarTableField,
  tyreCarRetailPriceTableField,
  tyreCarTableFieldExpand,
  tyreCarRetailPriceTableFieldExpand,
  selectFilterFields,
  selectFilterFieldsDisabled,
  attributeFilterFields,
  rangeFilterFields,
} from './configs.js';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    RimconfigSelectHistoryBar,
    RimconfigBreadcrumb,
    TyreSearch,
  },
  data() {
    return {
      isStart: true,
      isLoading: false,
      isLoadingPagination: false,
      isNoMorePaginationResult: false,
      isLoadingFilter: false,
      electedFilterPayload: {},
      selectedSelectFilterFields: [],
    };
  },
  computed: {
    ...mapState('rimconfigCarConfigurator', [
      'tpmsData',
      'carData',
      'selectedConfiguratorItems',
    ]),
    ...mapGetters('rimconfigCarConfigurator', ['getTyreSizeSelectFilterOptions']),
    ...mapState('tyre', {
      tyreData: 'tyreDataCar',
    }),
    ...mapState('pricing', {
      isOptIn: 'isOptIn',
    }),
    ...mapGetters('tyre', ['getTyreCarSelectFilterOptions']),
    breadcrumbItems() {
      return breadcrumbItemsNoState;
    },
    tableFieldSelectedItem() {
      return tableFieldSelectedItem;
    },
    tyreCarTableField() {
      if (!this.isOptIn) {
        return tyreCarTableField;
      } else {
        return tyreCarRetailPriceTableField;
      }
    },
    tyreCarTableFieldExpand() {
      if (!this.isOptIn) {
        return tyreCarTableFieldExpand;
      } else {
        return tyreCarRetailPriceTableFieldExpand;
      }
    },
    selectFilterOptions() {
      return {
        ...this.getTyreCarSelectFilterOptions(selectFilterFields),
        tyreSize: this.tyreSizeFilterOptions,
      };
    },
    attributeFilterFields() {
      return attributeFilterFields;
    },
    rangeFilterFields() {
      return rangeFilterFields;
    },
    tyreSizeFilterOptions() {
      return this.getTyreSizeSelectFilterOptions(this.$route.params, this.$route.query);
    },
  },
  // watch: {
  //   selectFilterOptions(val, oldVal) {
  //     if (oldVal.tyreSize.length === 0) {
  //       setTimeout(() => {
  //         this.$refs.tyreSearchRimconfigCartagTyre.setInitialFilter();
  //       }, 100);
  //     }
  //   },
  // },
  async mounted() {
    this.$store.dispatch('rimconfigCarConfigurator/setSelectConfiguratorStep', 3);
    this.isLoadingFilter = true;
    setTimeout(() => {
      this.$refs.tyreSearchRimconfigCartagTyre.setInitialConfiguratorTyreFilter();
    }, 100);
    // this.isLoading = true;
    // this.$store.dispatch('rimconfigCarConfigurator/getCarWheels', {
    //   carTag: this.$route.params.carTag,
    //   wheelManufacturer: this.$route.query.manufacturer,
    //   wheelDesigns: this.$route.query.design,
    //   colors: this.$route.query.color,
    //   tyres: true,
    // })
    //   .then(() => {
    //     this.isLoading = true;
    //   });

    // For tyre search component
    if (!this.$route.query.search) {
      this.selectedSelectFilterFields = selectFilterFields;
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: 'car',
        commitVehicleType: 'car',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      return;
    }

    // if it's search
    this.selectedSelectFilterFields = selectFilterFieldsDisabled;
    const seasonFilterValue = this.$route.query.search.charAt(0).toUpperCase();
    if (['S','W','A','G'].includes(seasonFilterValue)) {
      let adjustedSeasonFilterValue = seasonFilterValue;
      if (seasonFilterValue === 'G') {
        adjustedSeasonFilterValue = 'A';
      }
      this.selectedFilterPayload = {
        ...this.selectedFilterPayload,
        seasons: adjustedSeasonFilterValue,
      };
    }

    // if already had search data from front page
    if (this.tyreDataSearch.length > 0) {
      this.isStart = false;
      return;
    }

    this.isLoading = true;
    // if it's search and page is refreshed
    try {
      await this.$store.dispatch('tyre/searchTyreResult', {
        searchValue: this.$route.query.search,
        filters: {
          ...this.selectedFilterPayload,
        },
      });
    } catch (err) {
      const data = err.response.data;
      const status = err.response.status;
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: 'Error tyres',
        message: `<p>${data['error_title']} - ${status}</p>`,
      });
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
      this.isStart = false;
    }
  },
  methods: {
    setCarSelectorStep(step) {
      const promise1 = this.$store.dispatch('rimconfigCarSelector/setStep', step);
      const promise2 = this.$store.dispatch('rimconfigCarSelector/setSearchByKbaNumber', false);
      Promise.all([promise1, promise2])
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-configurator',
          }));
        });
    },
    async onClickWheels() {
      try {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.$route.params.carTag);
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-configurator_carTag',
          carTag: this.$route.params.carTag,
        }));
      }
    },
    async onClickWheelDetail() {
      try {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.$route.params.carTag);
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        const { manufacturer, design, color } = this.$route.query;
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-configurator_carTag',
          params: {
            carTag: this.$route.params.carTag,
          },
          query: {
            wheelDetail: true,
            manufacturer,
            design,
            color,
          },
        }));
      }
    },
    async onClickTpms() {
      try {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.$route.params.carTag);
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        const { manufacturer, design, color } = this.$route.query;
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-configurator_carTag-tpms',
          params: {
            carTag: this.$route.params.carTag,
            selectedWheel: this.selectedConfiguratorItems.wheel.isCombination === true ? 
              `${this.selectedConfiguratorItems.wheel.cartItems[0].man}+${this.selectedConfiguratorItems.wheel.cartItems[1].man}`
              : this.selectedConfiguratorItems.wheel.cartItems[0].man,
          },
          query: {
            manufacturer,
            design,
            color,
            wheel: this.selectedConfiguratorItems.wheel.assignIds.join('+'),
            quantity: this.selectedConfiguratorItems.wheel.quantity,
            isCombination: this.selectedConfiguratorItems.wheel.isCombination,
          },
        }));
      }
    },

    // For tyre search component
    getFilterPayload(filters) {
      let adjustedFilters;
      if (filters.tyreSizes) {
        let tyreSizeParts= filters.tyreSizes.join().split(' ');
        adjustedFilters = {
          ...filters,
          widths: [tyreSizeParts[0].split('/')[0]],
          aspectRatios: [tyreSizeParts[0].split('/')[1]],
          diameters: [tyreSizeParts[1]],
        };
      } else {
        adjustedFilters = filters;
      }
      let payload = {};
      Object.keys(adjustedFilters).forEach((key) => {
        if (Array.isArray(adjustedFilters[key]) && adjustedFilters[key].length > 0) {
          payload = {
            ...payload,
            [key]: adjustedFilters[key].join(),
          };
        } else if (!Array.isArray(adjustedFilters[key]) && adjustedFilters[key] || filters[key] === 0) {
          payload = {
            ...payload,
            [key]: adjustedFilters[key],
          };
        }
      });

      return payload;
    },
    async onSortTable({ activeSortCol, status }) {
      if (this.$route.query.search) {
        this.onSortTableIsSearch({ activeSortCol, status });
        return;
      }

      this.isLoading = true;
      this.isNoMorePaginationResult = false;
      if (status === 'none') {
        delete this.selectedFilterPayload.sortField;
        delete this.selectedFilterPayload.sortDirection;
      } else {
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          sortField: activeSortCol,
          sortDirection: status,
        };
      }

      try {
        await this.$store.dispatch('tyre/getTyres', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
        });
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      }
    },
    async onSortTableIsSearch({ activeSortCol, status }) {
      this.isLoading = true;
      this.isNoMorePaginationResult = false;
      if (status === 'none') {
        delete this.selectedFilterPayload.sortField;
        delete this.selectedFilterPayload.sortDirection;
      } else {
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          sortField: activeSortCol,
          sortDirection: status,
        };
      }

      try {
        let searchPayload = this.$route.query.search;
        const seasonFilterValue = searchPayload.charAt(0).toLowerCase();

        if (['s','w','a','g'].includes(seasonFilterValue)) {
          let adjustedSeasonFilterValue = seasonFilterValue;
          if (seasonFilterValue === 'g') {
            adjustedSeasonFilterValue = 'a';
          }
          const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
          if (filteredSeasons.length !== 1) {
            searchPayload = searchPayload.slice(1);
          } else if (filteredSeasons.length === 1
              && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
          ) {
            searchPayload = searchPayload.slice(1);
          }
        }

        const splitSearchValues = searchPayload.split(' ');
        if (splitSearchValues.length > 1) {
          const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
          if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
            searchPayload = splitSearchValues[0];
          } else if (
            filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
          ) {
            searchPayload = splitSearchValues[0];
          }
        }

        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            isSearch: true,
          },
        });
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async onNextPage(page) {
      if (this.$route.query.search) {
        this.onNextPageIsSearch(page);
        return;
      }
      this.isLoadingPagination = true;
      try {
        await this.$store.dispatch('tyre/getTyresNextPage', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
          page: page,
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
    async onNextPageIsSearch(page) {
      this.isLoadingPagination = true;
      let searchPayload = this.$route.query.search;
      const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
      if (['s','w','a','g'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'g') {
          adjustedSeasonFilterValue = 'a';
        }
        const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
        if (filteredSeasons.length !== 1) {
          searchPayload = searchPayload.slice(1);
        } else if (filteredSeasons.length === 1
            && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
        ) {
          searchPayload = searchPayload.slice(1);
        }
      }

      const splitSearchValues = searchPayload.split(' ');
      if (splitSearchValues.length > 1) {
        const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
        if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
          searchPayload = splitSearchValues[0];
        } else if (
          filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
        ) {
          searchPayload = splitSearchValues[0];
        }
      }

      try {
        await this.$store.dispatch('tyre/searchTyreResultNextPage', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            page: page,
            isSearch: true,
          },
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }

        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
    onInputSelectFilter(filters) {
      if (filters.initialSearch) {
        this.selectedFilterFields = selectFilterFields;
        this.isStart = false;
        delete filters.initialSearch;
      }

      const payload = this.getFilterPayload(filters);
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: this.$route.query.search ? '' : 'car',
        query: this.$route.query.search || '',
        commitVehicleType: 'car',
        ...payload,
      }).finally(() => {
        this.isLoadingFilter = false;
      });
    },
    async onSearchFilter(filters) {
      if (this.$route.query.search) {
        this.onSearchFilterIsSearch(filters);
        return;
      }

      const payload = this.getFilterPayload(filters);
      this.selectedFilterPayload = { // initial tyre table sort
        ...payload,
        sortField: 'bestPrice',
        sortDirection: 'ASC',
      };
      this.isLoading = true;
      this.isNoMorePaginationResult = false;

      try {
        await this.$store.dispatch('tyre/getTyres', {
          vehicleTypes: 'car',
          ...this.selectedFilterPayload,
        });
        this.isStart = false;
        if (filters.isVehicleType && this.tyreData.length === 0) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-tyre-found-header'),
            message: this.$t('langkey.please-select-another-vehicle-type'),
          });
        }
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      }
    },
    async onSearchFilterIsSearch(filters) {
      const payload = this.getFilterPayload(filters);
      this.selectedFilterPayload = {
        ...payload,
        sortField: 'bestPrice',
        sortDirection: 'ASC',
      };
      this.isLoading = true;
      this.isNoMorePaginationResult = false;

      let searchPayload = this.$route.query.search;
      const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
      if (['s','w','a','g'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'g') {
          adjustedSeasonFilterValue = 'a';
        }
        const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
        if (filteredSeasons.length !== 1) {
          searchPayload = searchPayload.slice(1);
        } else if (
          filteredSeasons.length === 1 &&
            adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
        ) {
          searchPayload = searchPayload.slice(1);
        }
      }

      const splitSearchValues = searchPayload.split(' ');
      if (splitSearchValues.length > 1) {
        const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
        if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
          searchPayload = splitSearchValues[0];
        } else if (
          filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
        ) {
          searchPayload = splitSearchValues[0];
        }
      }

      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: searchPayload,
          filters: {
            ...this.selectedFilterPayload,
            isSearch: true,
          },
        });
        this.isStart = false;
        if (filters.isVehicleType && this.tyreData.length === 0) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.no-tyre-found-header'),
            message: this.$t('langkey.please-select-another-vehicle-type'),
          });
        }
      } catch (err) {
        if (err === 'noResult') {
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    onClearTable() {
      this.selectedSelectFilterFields = selectFilterFields;
      this.isLoadingFilter = true;
      const vehicleType = 'car';
      if (this.$route.query.search) {
        this.$refs.tyreSearchRimconfigCartagTyre.onResetInitFilterVehicleType();
        this.$router.replace({
          name: this.$route.name,
          query: null,
        });
      }

      this.$store.dispatch('tyre/getTyresConcatenated', {
        vehicleTypes: vehicleType,
        commitVehicleType: 'car',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      this.$store.dispatch('tyre/clearTyresTable',{
        vehicleTypes: vehicleType,
        commitVehicleType: 'car',
      });
    },
  },
};
</script>